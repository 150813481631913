var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"task-card",staticStyle:{"margin-bottom":"5px"},attrs:{"accordion":""}},[_c('v-expansion-panel',{staticClass:"outlined-card"},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"la-angle-down"}},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('b',{staticClass:"mr-3 task-title",staticStyle:{"line-height":"1.3"}},[_vm._v(_vm._s(_vm.task.title))]),(_vm.task.metadata && _vm.task.metadata.data)?_c('span',{staticClass:"grey--text ml-1 mr-2"},[_vm._v(" "+_vm._s(_vm.task.metadata.data.mission)+" ")]):_vm._e(),_c('span',{staticClass:"circle",style:({
              backgroundColor: _vm.details.color,
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              display: 'inline-block',
            })})])])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.truncate(_vm.task.description, 400)))]),(_vm.task.createdAt || _vm.task.endDate)?_c('v-card-subtitle',{staticClass:"px-0 pb-0 mb-4",staticStyle:{"font-size":"12px"}},[(_vm.task.deadline)?_c('span',{staticClass:"black--text mr-3"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("la-calendar-check")]),_c('span',[_c('span',{staticClass:"black--text",staticStyle:{"font-weight":"500"}},[_vm._v("Due:")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.task.deadline))+" ")])],1):_vm._e(),_c('text-button',{staticClass:"small-text",attrs:{"color":"green"},on:{"click":function($event){_vm.showCompleteTaskDialog = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green","small":""}},[_vm._v("la-check")]),_vm._v(" Mark as done")],1)],1):_vm._e(),(_vm.task.metadata.action)?_c('PrimaryButton',{attrs:{"to":_vm.task.metadata.action.to,"block":""}},[_vm._v(" "+_vm._s(_vm.task.metadata.action.label)+" ")]):_vm._e()],1),_c('ConfirmDialog',{attrs:{"loading":_vm.resolveLoading},on:{"confirm":function($event){return _vm.resolveTask(_vm.task.id)}},model:{value:(_vm.showCompleteTaskDialog),callback:function ($$v) {_vm.showCompleteTaskDialog=$$v},expression:"showCompleteTaskDialog"}},[_c('div',{staticClass:"message"},[_vm._v("Are you sure you have completed this task ?")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }