var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"d-flex justify-space-between mb-2",staticStyle:{"width":"100%"}},[_c('h1',{staticClass:"font-size-18 font-weight-600 m-0"},[_vm._v("Pending Tasks")]),(_vm.widget)?_c('TextButton',{staticClass:"text-decoration-underline",attrs:{"to":{
        name: _vm.$routes.Tasks,
      },"small":""}},[_vm._v("View All")]):_vm._e()],1),_c('ApolloQuery',{attrs:{"query":_vm.tasksQuery,"variables":{
      filter: _vm.filter,
    },"notifyOnNetworkStatusChange":""},on:{"result":_vm.onResult,"error":_vm.onError},scopedSlots:_vm._u([{key:"default",fn:function({ query, result: { loading }, isLoading }){return [(loading || isLoading)?_vm._l((5),function(n){return _c('v-card',{key:n,staticClass:"mb-3",attrs:{"flat":""}},[_c('v-skeleton-loader',{attrs:{"transition":"scale-transition","type":"list-item-two-line"}})],1)}):_c('div',{staticClass:"expansion-panel-row"},[_c('v-card',{staticClass:"mb-4 box-shadow",staticStyle:{"min-height":"133px"},attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pending-task-text-wrap px-5 py-6"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.tasks.length)+" Pending Tasks ")])]),_c('v-spacer'),_c('FilterBar',{ref:"filterBar",attrs:{"flat":true,"dateFilters":[
                {
                  field: 'createdAtDate',
                  label: 'Date',
                },
              ]},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-tabs',{staticClass:"pb-3 tasks-tab-group",staticStyle:{"border-bottom":"none !important"},attrs:{"next-icon":"la-angle-right","prev-icon":"la-angle-left","active-class":"task-tab-item-active","hide-slider":"","show-arrows":""},model:{value:(_vm.selectedTaskTab),callback:function ($$v) {_vm.selectedTaskTab=$$v},expression:"selectedTaskTab"}},_vm._l((_vm.taskTabItems),function(tab,key){return _c('v-tab',{key:`tasks_tab_for_${tab}`,staticClass:"text-capitalize px-2",attrs:{"ripple":false}},[_vm._v(_vm._s(tab)+" "),_c('span',{staticClass:"tab-group-count-chip"},[_vm._v(_vm._s(_vm.getTasksCount(key)))])])}),1)],1),_vm._l((_vm.getRenderedTasks(_vm.selectedTabKey)),function(task,i){return _c('task-card',{key:`task_item_${i}`,attrs:{"task":task,"refetchQueries":_vm.refetchQueries}})}),(!_vm.loadingError && !_vm.getRenderedTasks(_vm.selectedTabKey).length)?[_c('FeedBackMessage',{staticClass:"outlined-card box-shadow",attrs:{"resource":"tasks","preset":"emptyState","icon":"la-tasks","customMessage":"Tasks assigned to you will show here"}})]:_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('v-spacer'),(_vm.widget)?_c('TextButton',{attrs:{"to":{
              name: _vm.$routes.Tasks,
            }}},[_vm._v("See more tasks")]):_vm._e()],1)],2),(!loading)?[(_vm.loadingError)?_c('FeedBackMessage',{staticClass:"outlined-card box-shadow",attrs:{"resource":"tasks","preset":"loadingError","buttonOnClick":() => _vm.handleLoadingErrorButtonClick(query)}}):(_vm.networkError)?_c('FeedBackMessage',{staticClass:"outlined-card box-shadow",attrs:{"resource":"tasks","preset":"networkError"}}):_vm._e()]:_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }