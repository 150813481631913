
import { Vue, Component, Prop } from "vue-property-decorator"
import { Task, TasksQuery, TasksQueryQuery } from "@/gql"
import { FetchResult } from "apollo-link"
import TaskCard from "@/components/tasks/TaskCard.vue"
import moment from "moment"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { ApolloError } from "@apollo/client/core"
import { SmartQuery } from "vue-apollo/types/vue-apollo"
import { ApolloQueryResult, NetworkStatus } from "@apollo/client/core"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"

enum TaskTypes {
  All = "All",
  Approval = "Approval",
  Contract = "ConsultantContract",
  Mission = "MissionLifecycle",
  Survey = "SurveyAssignment",
}

@Component({ components: { TaskCard, FeedBackMessage } })
export default class Tasks extends Vue {
  readonly moment = moment
  readonly tasksQuery = TasksQuery
  tasks: Task[] = []
  loadingError = false
  networkError = false
  filter: FilterType = {}

  @Prop() readonly widget?: boolean

  loading = false
  selectedTaskTab: any | null = null

  get taskTabItems() {
    return {
      [TaskTypes.All]: "All",
      [TaskTypes.Approval]: "Approvals",
      [TaskTypes.Mission]: "Missions",
      [TaskTypes.Survey]: "Surveys",
      [TaskTypes.Contract]: "Contracts",
    }
  }

  getRenderedTasks(key: string) {
    return this.widget ? (this.groupedTasks[key] || []).slice(0, 8) : this.groupedTasks[key] || []
  }

  getTasksCount(tab: TaskTypes) {
    if (tab == TaskTypes.All) {
      return this.tasks.length
    }

    return this.groupedTasks[tab]?.length || 0
  }

  get selectedTabKey() {
    const mapping: Record<number, TaskTypes> = {
      0: TaskTypes.All,
      1: TaskTypes.Approval,
      2: TaskTypes.Mission,
      3: TaskTypes.Survey,
      4: TaskTypes.Contract,
    }

    return mapping[this.selectedTaskTab || 0]
  }

  get groupedTasks(): { [taskType: string]: Task[] } {
    const grouped = this.tasks.reduce((groupedTasks, task) => {
      if (!groupedTasks[task.taskableType!]) groupedTasks[task.taskableType!] = []
      groupedTasks[task.taskableType!].push(task)
      return groupedTasks
    }, {} as { [taskType: string]: Task[] })

    grouped[TaskTypes.All] = this.tasks
    return grouped
  }

  onTaskResult(result: FetchResult<TasksQueryQuery>) {
    if (result.data && result.data.tasks && result.data.tasks) {
      // Sort by days to deadline
      this.tasks = result.data.tasks.sort((a, b) =>
        moment(a.deadline).diff(moment(b.deadline), "days")
      )
    }
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.loading = false
      this.loadingError = false

      if (result.data && result.data.tasks) {
        // Sort by days to dealine

        this.tasks = result.data.tasks.sort((a: any, b: any) =>
          moment(a.deadline).diff(moment(b.deadline), "days")
        )
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.loadingError = true
    }
  }

  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.loadingError = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<any>) {
    this.$set(this, "loading", true)
    await query.refetch()
  }

  refetchQueries() {
    return [
      {
        query: TasksQuery,
        variables: {
          filter: this.filter,
        },
      },
    ]
  }
}
