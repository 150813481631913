
import { Component, Vue, Prop } from "vue-property-decorator"
import {
  Task,
  ResolveTaskMutation,
  RejectTaskMutation,
  RejectTaskMutationMutation,
  ResolveTaskMutationMutation,
  ResolveTaskMutationMutationVariables,
  RejectTaskMutationMutationVariables,
} from "@/gql"
import moment from "moment"

@Component
export default class TaskCard extends Vue {
  @Prop({ required: true }) task!: Task

  @Prop({ required: false })
  refetchQueries!: any

  taskActionData: Record<string, unknown> = {}
  resolveLoading = false
  rejectLoading = false
  showCompleteTaskDialog = false

  statusText(task: Task, days_to_deadline: number) {
    if (!task.completedAt) {
      if (days_to_deadline < 0) {
        return Math.abs(days_to_deadline) + " days overdue"
      } else if (days_to_deadline == 0) {
        return "Task is due today"
      } else if (days_to_deadline > 0) {
        const day = days_to_deadline > 1 ? "days" : "day"
        return `${days_to_deadline} ${day} left`
      }
    } else {
      return "Task Completed"
    }
  }

  get details() {
    const task = this.task,
      days_to_deadline = moment(task.deadline).diff(moment(), "days")

    return {
      days_to_deadline: days_to_deadline,
      color: task.completedAt ? "green" : days_to_deadline > 3 ? "orange" : "#ff7e95",

      icon: task.completedAt ? "la-check" : "la-exclamation",
      statusText: this.statusText(task, days_to_deadline),
    }
  }

  async resolveTask(id: number) {
    try {
      this.resolveLoading = true

      // Mutate
      const result = await this.$apollo.mutate<
        ResolveTaskMutationMutation,
        ResolveTaskMutationMutationVariables
      >({
        mutation: ResolveTaskMutation,
        variables: {
          id: id,
        },
        refetchQueries: this.refetchQueries,
      })

      // Done
      if (result.data) {
        if (result.data.resolveTask.error) {
          this.addMutationError(result.data.resolveTask.error)
        } else {
          this.addSuccess(`Task completed successfully!`)
          this.showCompleteTaskDialog = false
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
    } finally {
      this.resolveLoading = false
    }
  }

  async rejectTask(id: number) {
    try {
      this.rejectLoading = true

      // Mutate
      const result = await this.$apollo.mutate<
        RejectTaskMutationMutation,
        RejectTaskMutationMutationVariables
      >({
        mutation: RejectTaskMutation,
        variables: {
          id: id,
          data: this.taskActionData,
        },
        refetchQueries: this.refetchQueries,
      })

      // Done
      if (result.data) {
        if (result.data.rejectTask.error) {
          this.addMutationError(result.data.rejectTask.error)
        } else {
          this.addSuccess(`Task rejected successfully!`)
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
    } finally {
      this.rejectLoading = false
    }
  }
}
